<template>
    <div class="home-page-content">
        <div class="header">
            <h1>Welcome to the sustainability assessment project</h1>
        </div>
        <div class="main-content">
            <div class="left-content">
                <img class="left-image" src='../assets/planetary_boundries.png' alt="Planetary Boundaries" />
            </div>
            <div class="right-content">
                <p class="highlight"><strong>To go directly to a city assessment, click on the ‘Search’ tab, type in name of city you are interested in (or use map to select city).</strong></p>
                <p>This site provides ‘sustainability assessments’ for the world’s cities – those urban regions with more than 5 million residents. Consistent with the World Urbanization Prospects, United Nations, the overall urban system (city region) is assessed. The list of cities and populations projected to 2100 are available at <a href='http://journals.sagepub.com/doi/abs/10.1177/0956247816663557'>Hoornweg and Pope, 2016.</a></p>
                <p>Sustainability is measured under two broad areas; bio-physical and socio-economic. Tables 1 and 2 list aspirational values for each metric, derived from locally-relevant planetary boundaries and global Sustainable Development Goals (SDG) Ambio report.</p>
                <p><strong>Bio-physical indicators</strong> are derived mainly from planetary boundaries developed by Rockstrom (2009) and updated by Steffen et al. (2015). See figure.</p>

                <router-link :to="{ path: '/table' }" class="styled-link" >Table</router-link>
            </div>
        </div>
        <div class="section softRoundedBorder">
            <h2>Bio-physical indicators</h2>
            <p>To assess bio-physical boundaries from the perspective of cities, the following indicators are used:</p>
            <ul>
                <li><strong>Climate change</strong> – GHG emissions</li>
                <li><strong>Biodiversity loss</strong> – ecological footprint, index of biodiversity impact</li>
                <li><strong>Fresh water use</strong> – per capita water consumption, potable water supply, index of embodied water consumption</li>
                <li><strong>Change in land use</strong> – local land use change, population density, Index of global land use</li>
                <li><strong>Nitrogen cycle</strong> – estimated per capita consumption patterns</li>
                <li><strong>Chemical pollution</strong> – solid waste collection, wastewater collection, PM 10, PM 2.5, Index of solid waste (under development)</li>
                <li><strong>Urban resilience</strong> – number of natural disaster related deaths, annual GDP, threated GDP, GDP loss due to natural disasters, Index of resilience</li>
            </ul>
        </div>
        <div class="section softRoundedBorder">
            <h2>Socio-economic indicators</h2>
            <p>Socio-economic indicators are derived mainly from the Sustainable Development Goals as proposed through the United Nations, 2015. See figure.</p>
            <div class="text-center">
                <img src='../assets/s-e_indicators.png' alt="Socio-economic Indicators"/>
            </div>
            <ul>
                <li><strong>Youth opportunity</strong> – under 5 mortality, gender equity, females in schools, youth unemployment, life expectancy</li>
                <li><strong>Economy</strong> – unemployment rate, Gini coefficient, population living in slums/homeless, GDP</li>
                <li><strong>Energy access/intensity</strong> – authorized electrical service, clean energy for cooking, energy intensity</li>
                <li><strong>Mobility and Connectivity</strong> – number of automobiles, daily public transport trips, internet connections, mode of travel, transportation fatalities, commercial air connectivity</li>
                <li><strong>Institutions</strong> – ease of doing business, corruption of city officials, ratio of tax collected, debt service ratio</li>
                <li><strong>Basic services</strong> – solid waste collection, wastewater collection, potable water supply</li>
                <li><strong>Security and public safety</strong> – fire related deaths, homicides, violent crime rate, Index of health care.</li>
            </ul>
        </div>
        <div class="section softRoundedBorder">
            <h2>A note on Estimates and Confidence</h2>
            <p>As outlined in a Cities and Sustainability: A new approach, global targets (at city-level) are available for all indicators. These are provided in the ‘Target’ column for each city. For each estimated value, references are provided (see below). A ‘Confidence’ level is also provided representing the degree of confidence associated with the estimate. Generally, one star represents a general estimate, often down-scaled national estimates. Two stars represent referenced estimates specific to the city. Three stars denote peer reviewed information specific to the city. Four stars represent peer reviewed, and time series data. Five stars would represent peer reviewed, third party audited data representing the entire urban area. World Council on City Data would represent this standard, however currently there are no complete urban agglomerations where all local governments are reporting city metrics, subject to third party auditing.</p>
        </div>
        <div class="section softRoundedBorder">
            <p>Efforts are underway to improve data availability, refine indicators, and expand the list of reported cities. As SDGs and planetary boundaries are updated, city-based targets will be updated as well.</p>
        </div>
        <app-footer position="relative" />
    </div>
</template>

<script>
import Footer from './Footer.vue'

export default {
    components: {
        'app-footer': Footer
    },
    name: 'NavigationComponent',
}
</script>

<style scoped>
.home-page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    background-color: rgb(226, 226, 226);
    padding-right: 30px;
    text-align: center;
    width: 100%;
}

.header h1 {
    font-size: 1.7em;
    font-weight: bold;
    color: #2c3e50;
    margin: 0;
}

.main-content {
    display: flex;
    padding: 20px;
    align-items: flex-start;
}

.left-content {
    flex: 1;
    padding-right: 20px;
}

.left-image {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    padding: 10px;
}

.left-image:hover {
    transform: scale(1.05);
}

.right-content {
    flex: 2;
    padding-top: 55px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}

.highlight {
    font-size: 1.2em;
    font-weight: bold;
    color: #2c3e50;
    margin: 10px 0;
}

.section {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
}

.softRoundedBorder {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    background-color: #f9f9f9;
}

.text-center {
    padding-right: 500px;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

a {
    color: darkblue;
}
.styled-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4e95e0;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 200px; /* adjust as needed */
}

.styled-link:hover {
    background-color: #86acd4;
    transform: scale(1.05);
}
</style>
