<template>
  <div id="app">
    <div class="header">
      <div class="header-content">
        <h3>Sustainability Assessment</h3>
        <h6 class="tagline">Helping to measure sustainable development across the world's cities.</h6>
      </div>
      <div class="nav-links">
        <router-link to="/" class="nav-link">Home</router-link>
        <router-link to="/search" class="nav-link"><a @click="setSearchType('city')">Cities</a></router-link>
        <router-link to="/metropolitan" class="nav-link"> Metropolitan Areas</router-link>
        <router-link to="/ranks" class="nav-link">Index Ranking</router-link>
        <router-link to="/table" class="nav-link">Table</router-link>
        <router-link to="/contact" class="nav-link">Contact</router-link>
        
      </div>
      <div class="login-container" v-if="!$auth.loading">
        <h4 v-if="$auth.isAuthenticated" class="welcome-msg">Welcome, {{ $auth.user.name }}!</h4>
        <a v-if="!$auth.isAuthenticated" @click="login" class="button is-dark">
          <b-button variant="outline-primary">Sign In</b-button>
        </a>
        <a v-if="$auth.isAuthenticated" @click="logout" class="button is-dark">
          <b-button variant="outline-primary">Log Out</b-button>
        </a>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>


<script>
export default {
  name: 'app',
  data () {
    return {}
  },
  computed: {
    quickLinks () {
      return this.$store.getters.getQuickLinks
    },
    ready () {
      return this.$store.getters.isReady
    }
  },
  methods: {
    setSearchType(type) {
      this.$store.commit('setSearchType', type)
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  created () {
    console.log('app created')
    this.$store.dispatch('init')
  },
  destroyed () {
    console.log('app destroyed')
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Improved font style */
}

#app {
  position: relative;
  margin: 0 auto;
  height: auto !important;
  height: 100%;
  min-height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: white;
  border-bottom: 1px solid darkslategray;
  color: #003c71;
}

.header-content h3 {
  margin: 0;
  font-size: 29px;
  color: #343a40;
}

.header-content h6{
  font-size: 14px
}

.nav-links {
  display: flex;
  gap: 5px; /* Smaller spacing between links */
  padding-left: 100px;
}

.tagline{
  font-size: 12px;
  padding-top: 2px;
}

.nav-link {
  position: relative;
  display: inline-block;
  padding: 5px 10px; /* Smaller padding */
  color: #003c71; /* Blue color */
  text-decoration: none;
  font-size: 17px; /* Smaller font size */
  transition: color 0.3s;
}

.nav-link:hover {
  color: darkblue; /* Darker blue on hover */
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #003c71; /* Blue underline */
  bottom: -2px;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: bottom right;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.login-container {
  display: flex;
  align-items: center;
}

.welcome-msg {
  margin-right: 20px;
  font-size: 18px;
  color: #343a40; /* Consistent color */
}

.button.is-dark {
  background: none;
  border: none;
  font-size: 16px; /* Adjust font size to match the links */
  color: #003c71;
  cursor: pointer;
}

.button.is-dark:hover {
  color: #003c71;
}

.snug {
  padding: 0 !important;
  margin: 0 !important;
}

.tight {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.fill {
  min-height: 500px;
  max-height: 500px;
  height: 500px;
  width: 100%;
}

.link-like {
  cursor: pointer;
}

.fixed-content {
      /* z-index: -1; */
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: calc(100% - 200px);
      /*position: fixed;*/
      /*top: 100px;*/
      margin-top: 100px;
      overflow: auto;
}

/* Loading spinner */
.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}
</style>