<template>
    <div class="container">
      <iframe
        class="map-iframe"
        :src="mapSrc"
        allowfullscreen
      ></iframe>
      <Footer />
    </div>
  </template>
  
  <script>
  import Footer from './Footer.vue';
  
  export default {
    name: 'MapComponent',
    components: {
      Footer,
    },
    data() {
      return {
        mapSrc: 'https://www.google.com/maps/d/embed?mid=1uVw3UDz14Cm3S_92-NxCA9N4xkg-LGc&ehbc=2E312F'
      };
    }
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .map-iframe {
    flex: 1;
    border: none;
  }
  </style>
  