<template>
    <div class="snug mapContainer">
        <div id="mapAreaParent">
            <div id="mapArea">
                
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data () {
        return {
            t: undefined, // timer for checking map at startup
            ready: false,
            // markers: [],
            map: undefined,
            selectedIconPath: 'greenPin.svg',
            unselectedIconPath: 'redPin.svg',
            selectedIcon: {},
            unselectedIcon: {},
            lastMarker: undefined,
            colors: ['#4283f4','#e6f23c','#42c5f4','#3ff4d6','#3ff463', '#f29224','#ed582a','#2f2fed','#ea36ed','#ef1a5d'],
            mapStyle: [
                {
                    "featureType": "administrative",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#46bcec"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        searchType () {
                return this.$store.getters.getSearchType === "city" ? 0 : 1
        },
        cities () {
            var allCities = this.$store.getters.getCities
            var cityList = []
            for(var i = 0; i < allCities.length; i++){
                if(allCities[i].megaRegion == this.searchType) {
                    cityList.push(allCities[i])
                }
            }
            return cityList
            // return this.$store.getters.getCities
        },
        activeCity () {
            return this.$store.getters.getActiveCity
        }
    },
    methods: {
        checkMapLibrary () {
            
            if(typeof(L) === "undefined" || this.cities.length === 0){
            // wait for map to load
                // console.log('map not ready')
            } else {
            // map loaded
                // console.log('map ready')
                
                clearInterval(this.t);
                // set up icons
                this.unselectedIcon = L.icon({
                    iconUrl: this.unselectedIconPath,
                    iconSize: new L.Point(50, 50),
                    // origin: new L.Point(0, 0),
                    iconAnchor: new L.Point(12.5, 25),
                    // scaledSize: new L.Size(25, 25)
                })
            
                this.selectedIcon = L.icon({
                    iconUrl: this.selectedIconPath,
                    iconSize: new L.Point(50, 50),
                    // origin: new L.Point(0, 0),
                    iconAnchor: new L.Point(12.5, 25),
                    // scaledSize: new L.Size(25, 25)
                })

                this.ready = true
                this.setupMap()
                
            }
        },
        panMap (city) {
            // remove coloring of previously selected marker
            if(this.lastMarker !== undefined) {
                this.lastMarker.selected = false
                this.lastMarker.setIcon(this.unselectedIcon)  
            }
            
            // pan to location of selected city
            var latLng = new L.LatLng(city.lat, city.lng);
            this.map.panTo(latLng);
            
            // set marker as selected
            city.marker.selected = true
            city.marker.setIcon(this.selectedIcon)  
            
            // update the last selected marker as this one
            this.lastMarker = city.marker
        },
        setupMap () {
            console.log('drawing map')
            var mapOptions = {
                zoom: 5,
                center: new L.LatLng(43.6532, -79.3832) // toronto
            }

            this.map = new L.Map('mapArea', mapOptions)
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(this.map);

            var infoWindow = new L.popup({offset: L.point(13,-10)})
            this.map.window = infoWindow
       
            var createMarker = (info, index) => {
                // info holds city info
                // drawing marker

                // console.log('adding marker with lat ' + info.lat + ' and lng ' + info.lng)
                var vm = this; // avoid losing scope of this
                
                let m = new L.Marker([info.lat, info.lng], {
                    title: info.name,
                    icon: vm.unselectedIcon    
                })
                // m.addTo(this.map)

                
                // var marker = new L.Marker(new L.LatLng(info.lat, info.lng), {
                //     title: info.name,
                //     icon: vm.unselectedIcon
                // })

                // // marker.setIcon('../assets/greenPin.svg');
                m.color = this.colors[index]
                m.selected = false
                m.city = info
                m.points = [] // hold array of each circle making the region
                
                m.highlight = (m, mode) => {
                    for(var i = 0; i < m.points.length; i++) {
                        if(mode) { // true - highlight
                            // console.log('highlighting region')
                            m.points[i].setOptions({
                                fillColor: 'green',
                                strokeColor: '#000000',
                                fillOpacity: 0.65,
                                zIndex: 10
                            });
                        } else {
                            // false - un-highlight
                            // console.log('de-highlighting region')
                            m.points[i].setOptions({
                                fillColor: marker.color,
                                strokeColor: '#000000',
                                fillOpacity: 0.35,
                                zIndex: 0
                            });
                        }    
                    }
                }

                info.marker = m // circular reference needed for handling highliting regions
                // vm.markers.push(marker) // unused

                m.addEventListener('mouseover', function(){
                    // can move some of this to marker.highlight()
                    m.highlight(m,true);
                    m.setIcon(vm.selectedIcon);
                    infoWindow
                        .setContent('<b>' + info.name + '</b>') 
                        .setLatLng(m.getLatLng())
                        .openOn(vm.map);
                })

                m.addEventListener('mouseout', function(){
                    // can move some of this to marker.highlight()
                    if(!this.selected){
                        this.setIcon(vm.unselectedIcon)    
                    }
                    m.highlight(m,false)
                    infoWindow.close();
                });
                
                m.addEventListener('click', () => {
                    vm.setCity(m.city)
                }); 
                
                if(info.megaRegion == 1) {
                    createPolygon(info, m)
                }

                m.addTo(vm.map)

            // $scope.markers.push(info);
            } // end createMarker
            
            var createPolygon = (info, marker) => {
                var vm = this;
                var coords = JSON.parse(info.coords);
                var points = [];
                
                // console.log('creating circle with color: ' + marker.color)
                
                for(var i = 0; i < coords.length; i++){
                    var cityCircle = new L.Circle({
                        strokeColor: '#333333',
                        strokeOpacity: 1.0,
                        strokeWeight: 1,
                        fillColor: marker.color,
                        fillOpacity: 0.35,
                        zIndex: 0,
                        map: vm.map,
                        center: {lat: coords[i][0], lng: coords[i][1]},
                        radius: 100000
                    });
                    cityCircle.marker = marker;
                    cityCircle.marker.points.push(cityCircle) // circular but now marker can reference the circle
                    
                    cityCircle.addEventListener('mouseout', function(){
                        // next 4 lines can move to marker.highlight()
                        if(!this.marker.selected){
                            this.marker.setIcon(vm.unselectedIcon)    
                        }
                        vm.map.window.close();
                        // call marker.highlight
                        this.marker.highlight(this.marker, false)
                    });
                    
                    cityCircle.addEventListener('mouseover', function(){
                        // redundant - next 3 lines can move to marker.highlight()
                        vm.map.window.setContent('<b>' + this.marker.title + '</b>');
                        vm.map.window.open(vm.map, this.marker);
                        this.marker.setIcon(vm.selectedIcon)
                        // call marker.highlight
                        this.marker.highlight(this.marker, true)
                    });
                    
                    cityCircle.addEventListener('click', () => {
                        vm.setCity(cityCircle.marker.city)
                    }); 
                }
            }

            // console.log('about to draw markers');
            // console.log(this.cities.length);
            for(var i = 0; i < this.cities.length; i++){
                createMarker(this.cities[i],i);
            }

            function openInfoWindow(e, selectedMarker){
                e.preventDefault();
                selectedMarker.fireEvent('click');
                // L.event.trigger(selectedMarker, 'click');
            }

        },
        setCity (city) {
            // console.log('setting city to: ' + city.name)
            this.$store.dispatch('setCity', city)
        }
    },
    watch: {
        activeCity (city) {
            // console.log('city changed: ' + city.name)
            if(this.ready){
                this.panMap(city)
            }
        },
        searchType () {
            if(this.ready){
                this.setupMap()
            }
        }
    },
    created () {
        // console.log('map created')
        this.t = setInterval(() => {this.checkMapLibrary()}, 50)
    },
    destroyed () {
        // console.log('map destroyed')
    }
}

</script>

<style>
.mapContainer {
    position: relative;
    width: 100% !important;
    height: 100% !important;
}
#mapAreaParent {
    position: absolute;
    background: pink;
    /*margin: 1 1 1 1;*/
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#mapArea {
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    background: lightblue;
}
</style>