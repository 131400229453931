<template>
    <div class="citySelection">

        <div class="cityFilter">
            <b-form-group size="sm" label="Filter Cities:">
                <b-form-input size="sm" v-model="filter" />
            </b-form-group>
            
            <b-row class="text-center">
                <b-col class="text-center">
                    <b-btn @click='loadPage' size="sm" variant="primary" :disabled="!activeCity">{{ activeCity ? 'Load ' + activeCity.name : 'Select a City' }}</b-btn>    
                </b-col>
            </b-row>
        </div>

        <div class="cityList">
            <b-list-group>
                <b-list-group-item class="tight link-like" :key="c.id" v-for="c in filteredCities" @click="setCity(c)">{{ c.country.trim() !== "" ? c.name + ", " + c.country : c.name }}</b-list-group-item>
            </b-list-group>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                filter: '',
                // megaRegions: 'include'
            }
        },
        computed: {
            activeCity () {
                return this.$store.getters.getActiveCity
            },
            searchType () {
                return this.$store.getters.getSearchType === "city" ? 0 : 1
            },
            cities () {
                // var allCities = this.$store.getters.getCities
                // var cityList = []
                // for(var i = 0; i < allCities.length; i++){
                //     if(allCities[i].megaRegion == this.searchType) {
                //         cityList.push(allCities[i])
                //     }
                // }
                // return cityList
                return this.$store.getters.getCities // comment this and uncomment above lines to enable filtering by city type
            },
            filteredCities () {
                return this.cities.filter( (element) => {
                    return (element.name + element.country).toUpperCase().match(this.filter.toUpperCase())
                });	
            }
        },
        methods: {
            setCity (city) {
                this.$store.dispatch('setCity', city)
            },
            loadPage () {
                if(!this.activeCity) return
                // this.$store.dispatch('getCityData', this.activeCity.id)
                this.$router.push('/city/' + this.activeCity.id)
            }
        },
        watch: {
            
        }
    }
</script>

<style>
.citySelection {
    position: relative;
}

.cityList {
    z-index: 2;
    position: relative;
    /* top: 140px; */
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
}

.cityFilter {
    z-index: 3;
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    height: 140px;
    padding: 10px;
    width: 100%;
}

.col-form-legend {
    font-size: 12px !important;
}

.padded {
    padding: 10px;
}

.list-group-item:hover {
    background: rgb(0,60,113);
    color: white;
}

.link-like:hover{
    cursor: pointer;
}


</style>