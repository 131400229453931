import Vue from 'vue'
import VueRouter from 'vue-router'
import BootstrapVue from "bootstrap-vue"
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import { store } from './store/store'
import { routes } from './routes'
import { domain, clientId } from '../auth_config.json';
import { Auth0Plugin } from './auth';

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

const router = new VueRouter({
  routes: routes,
  mode: 'history'
})

new Vue({
  el: '#app',
  store: store,
  router: router,
  render: h => h(App)
}).$mount('app');
