<template>
    <div class="fixed-content">
        <div class="page-content">
            <div class="snug leftArea">
                <app-city-list />
            </div>
        
            <div class="snug rightArea" style="border-left: 1px solid gray">
                <app-map-area />  
            </div>
        </div>
        <app-footer position="absolute" />
    </div>
</template>

<script>
    import MapArea from './Map.vue'
    import CityList from './CityList.vue'
    import Footer from './Footer.vue'

    export default {
        data () {
            return {}
        },
        components: {
            'appMapArea': MapArea,
            'appCityList': CityList,
            'appFooter': Footer
        },
        computed: {
            
        },
        methods: {
            
        },
        mounted () {
            // console.log('search page mounted')
        }
    }
</script>

<style>

    
    .page-content {
        display: flex;
        height: 100%;
    }

    .leftArea {
        /* position: relative; */
        height: 100%;
        width: 260px;
        /* flex: 1 0 0; */
        /* background: lightblue; */
        overflow-y: scroll;
        overflow-x: hidden;
    }
    
    .rightArea {
        height: 100%;
        flex: 1 0 0;
        /* background: slategray; */
        overflow: hidden;
    }
    
  
</style>