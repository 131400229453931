import Search from './components/Search.vue'
import Home from './components/Home.vue'
import CityData from './components/CityData.vue'
import RankPage from './components/RankPage.vue'
import Contact from './components/Contact.vue'
import Table from './components/Table.vue'
import MetropolitanArea from './components/MetropolitanArea.vue'

export const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/search',
        component: Search 
    },
    {
        path: '/city/:id',
        component: CityData
    },
    {
        path: '/ranks',
        component: RankPage
    },
    {
        path: '/table',
        component: Table
    },
    {
        path: '/contact',
        component: Contact
    },
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/metropolitan',
        component: MetropolitanArea
    }
]