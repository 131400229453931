<template>
    <div class="footer text-center" :class="{ abs : position === 'absolute'}">
        <b> &emsp; Working Draft: OntarioTech University. 2022.</b>
        <br> <br>
        <img src="../assets/OTU.png" style="width:110px"/>
    </div>
</template>

<script>
    export default {
        props: ['position']
    }
</script>

<style>
    
    .footer {
      /*position: absolute;*/
      /*bottom: 0;*/
      font-size: 0.8em;
      height: 100px;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      background: rgb(0,60,113);
      color: white;
      border-top: 1px solid darkslategray;
    }
    
    .abs {
        position: fixed !important;
        bottom: 0 !important;
    }
    
    .rel {
        position: relative;
    }

</style>